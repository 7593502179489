@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background: #546ccc;
}


/* nprogress */
#nprogress {
  left: 0;
  top: 0;
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: #ffffffa9;
  display: flex;
  align-items: center;
  justify-content: center;
}
#nprogress .bar {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  background: #546CCC;
}
#nprogress .spinner {
  display: inline-flex;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: auto;
}

#nprogress .spinner .spinner-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #e4e4ed;
  border-right-color: #546CCC;
  animation: lds-dual-ring 0.4s infinite linear;
}


@keyframes lds-dual-ring {
  to {
    transform: rotate(1turn);
  }
}
